import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import styled from "styled-components"
import { Navigation, Pagination, Scrollbar, A11y } from "swiper"

interface CategoryProps {
  name: string
  url: string
  imageUrl: string
}

const categories: CategoryProps[] = [
  {
    name: "Maty",
    url: "https://yogabazar.pl/pol_m_Mata-do-jogi_wedlug-marek_Maty-do-jogi-Sayoga-395.html",
    imageUrl: "/category/thumb_mats.jpg",
  },
  {
    name: "Koła",
    url: "https://yogabazar.pl/pol_m_Sayoga_Kola-do-jogi-Sayoga-403.html",
    imageUrl: "/category/thumb_wheel.jpg",
  },
  {
    name: "Paski do transportu",
    url: "https://yogabazar.pl/pol_m_Sayoga_Pasek-na-mate-do-jogi-Sayoga-405.html",
    imageUrl: "/category/thumb_carrying_strap2.jpg",
  },
  {
    name: "Klocki",
    url: "https://yogabazar.pl/pol_m_Sayoga_Klocki-do-jogi-Sayoga-404.html",
    imageUrl: "/category/thumb_blocks.jpg",
  },
  {
    name: "Pokrowce na maty",
    url: "https://yogabazar.pl/pol_m_Sayoga_Pokrowce-na-maty-Sayoga-400.html",
    imageUrl: "/category/thumb_bags.jpg",
  },
  {
    name: "Zafu",
    url: "https://yogabazar.pl/pol_m_Sayoga_Poduszki-Zafu-Sayoga-402.html",
    imageUrl: "/category/thumb_zafu2.jpg",
  },
  {
    name: "Wałki",
    url: "https://yogabazar.pl/pol_m_Sayoga_Bolstery-i-walki-do-jogi-Sayoga-401.html",
    imageUrl: "/category/thumb_bolster.jpg",
  },
]

const CarouselStyles = styled.div`
  margin: auto;
  min-height: 200px;
  .swiper {
    .swiper-button-next,
    .swiper-button-prev {
      background: #424242;
      color: white;
      height: 4rem;
      width: 4rem;
      border-radius: 2rem;
      &:after {
        font-size: 1rem;
        color: #ffffffa6;
      }
      &:hover {
        opacity: 0.7;
      }
    }
    a {
      &:hover {
        opacity: 0.8;
      }
    }
    @media screen and (max-width: 991px) {
      .swiper-button-next,
      .swiper-button-prev {
        display: none;
      }
    }
  }
`

export const ProductCategoryCarousel: React.FC = () => {
  return (
    <CarouselStyles className="max-w-6xl">
      <Swiper
        spaceBetween={10}
        slidesPerView={1.5}
        navigation
        modules={[Navigation]}
        cssMode
        loop
        loopAdditionalSlides={categories.length}
        breakpoints={{
          720: {
            slidesPerView: 3.5,
          },
          992: {
            slidesPerView: 4,
          },
        }}
      >
        {categories.map((category: CategoryProps, idx: number) => (
          <SwiperSlide key={idx}>
            <a
              href={category.url}
              target="_blank"
              className="no-underline text-inherit"
            >
              <img
                className="rounded-full w-3/4 m-auto"
                src={category.imageUrl}
              />
              <div className="text-center mt-4">{category.name}</div>
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </CarouselStyles>
  )
}
