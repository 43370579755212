import React from "react"

interface ButtonProps {
  onClick?: (event: React.MouseEvent) => any
  asAnchor?: boolean
  href?: string
  target?: string
  disabled?: boolean
}

export const Button: React.FC<
  ButtonProps & React.HTMLAttributes<HTMLElement>
> = ({
  children,
  onClick,
  asAnchor = false,
  href,
  target = "_self",
  className,
  disabled = false,
}) => {
  const getClasses = (): string =>
    `text-white bg-gray-600  font-medium rounded text-sm px-5 py-2.5 text-center mr-3 md:mr-0 no-underline ${
      className || ""
    } ${
      disabled
        ? "opacity-80"
        : "hover:bg-gray-700 focus:outline-none focus:no-underline"
    }`

  return asAnchor ? (
    <a href={href} target={target} className={getClasses()}>
      {children}
    </a>
  ) : (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={getClasses()}
    >
      {children}
    </button>
  )
}
