import React, { HTMLAttributes } from "react"
import { Header } from "../components"

interface FeatureProps {
  title: string
  iconUrl: string
}

const features: FeatureProps[] = [
  {
    title: "Zaprojektowane w Polsce",
    iconUrl: "/heart-icon.svg",
  },
  {
    title: "Maty odporne na pot",
    iconUrl: "/drop-icon.svg",
  },
  {
    title: "Oparte na wiedzy ekspertów",
    iconUrl: "/stars-icon.svg",
  },
  {
    title: "Ekologiczne materiały",
    iconUrl: "/leaf-icon.svg",
  },
  {
    title: "Wytrzymała konstrukcja",
    iconUrl: "/shield-icon.svg",
  },
  {
    title: "Na każdy poziom praktyki",
    iconUrl: "/yogin-icon.svg",
  },
]

export const BrandFeatures: React.FC<HTMLAttributes<HTMLElement>> = () => {
  return (
    <div className="grid gap-8 grid-cols-3 md:grid-cols-4">
      <div className="hidden md:block">
        <img src={"/home-image.jpg"} />
      </div>
      <div className="col-span-3">
        <Header>Akcesoria inspirowane miłością do jogi</Header>
        <p>
          Pasja, troska o środowisko oraz wieloletnie doświadczenie na macie
          stały się impulsem do stworzenia Sayogi - marki profesjonalnych
          akcesoriów do jogi. Z nimi rozpoczniesz jogową przygodę i odważnie
          podejmiesz nowe wyzwania.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 mt-4">
          {features.map((feature: FeatureProps, idx: number) => (
            <div key={idx} className="flex">
              <div className="w-14 h-14 bg-orange-dark rounded-full relative shrink-0">
                <img
                  className="w-6 h-6 absolute translate-center inset-2/4"
                  src={feature.iconUrl}
                />
              </div>
              <span className="px-2 flex flex-col justify-center">
                <span className="font-sans text-lg">{feature.title}</span>
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
