import React from "react"
import { Button, Header } from "../components"

export const HeroSection: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  className = "",
}) => {
  return (
    <div
      className={`w-full bg-cover bg-center ${className}`}
      style={{
        height: "60vh",
        backgroundImage: `url('/herobanner.jpg')`,
      }}
    >
      <div className="flex flex-col h-full place-items-center justify-center">
        <div>
          <Header className="mt-12 mb-8 text-center text-white font-semibold txt-shadow">
            Sayoga - polska marka akcesoriów do jogi stworzona przez joginów dla
            joginów
          </Header>
        </div>
        <div className="mt-12">
          <Button
            asAnchor={true}
            target={"_blank"}
            href={"https://yogabazar.pl/firm-pol-1580304434-Sayoga.html"}
          >
            Zobacz ofertę u dystrybutora
          </Button>
        </div>
      </div>
    </div>
  )
}
