import React from "react"
import { Button } from "../components"

export const Navbar: React.FC = () => {
  return (
    <nav className="bg-white px-2 sm:px-8 fixed w-full z-20 top-0 left-0">
      <div className="container flex flex-wrap justify-between items-center mx-auto">
        <div className="flex items-center">
          <img
            src={"/logo_sayoga.png"}
            className="max-h-12 mt-4"
            alt="Sayoga Logo"
          />
        </div>
        <div className="flex md:order-2">
          <Button asAnchor={true} href={"https://yogabazar.pl/firm-pol-1580304434-Sayoga.html"} target="_blank">
            Kup Teraz
          </Button>
        </div>
      </div>
    </nav>
  )
}
