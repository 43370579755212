import React from "react"
interface HeaderProps {
  children: React.ReactNode | string
  as?: "h1" | "h2" | "h3" | "h4" | "h5"
}

const headerClassMapping: Record<string, string> = {
  h1: "text-4xl mb-2.5",
  h2: "text-3xl mb-2",
  h3: "text-2xl mb-1.5",
  h4: "text-xl mb-1",
  h5: "text-lg mb-1",
}

export const Header: React.FC<
  HeaderProps & React.HTMLAttributes<HTMLElement>
> = ({ children, as: Tag = "h2", className }) => {
  const getClasses = (): string =>
    `font-sans ${headerClassMapping[Tag]} ${className || ""}`

  return <Tag className={getClasses()}>{children}</Tag>
}
