import * as React from "react"
import ReactPlayer from "react-player"
import {
  HeroSection,
  ProductCategoryCarousel,
  Navbar,
  Container,
  BrandFeatures,
} from "../components"
import styled from "styled-components"
import SayogaLogo from "../../static/sayoga-logo-horizontal.svg"
import InstagramIcon from "../../static/instagram.svg"
import FacebookIcon from "../../static/facebook.svg"
import { Helmet } from "react-helmet"

const MoviePlayerStyles = styled(Container)`
  > .player-container {
    width: 100%;
    padding-top: 56%;
    position: relative;
    .movie-player {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
`

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="index, follow" />
        <title>Sayoga</title>
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GOOGLE_ANALYTICS}`}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.GATSBY_GOOGLE_ANALYTICS}', {
              page_path: window.location.pathname,
            });
          `,
          }}
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        {process.env.GATSBY_INCLUDE_GTM && (
          <meta
            name="google-site-verification"
            content={process.env.GATSBY_GTM}
          />
        )}
      </Helmet>
      <Navbar />
      <HeroSection title="Sayonara" className="mt-20" />
      <Container className="max-w-8xl py-12">
        <ProductCategoryCarousel />
      </Container>
      <Container className="py-12 px-12 max-w-6xl">
        <BrandFeatures />
      </Container>
      <MoviePlayerStyles className="mx-auto max-w-xl py-8">
        <div className="player-container">
          <ReactPlayer
            controls
            width={"100%"}
            height={"100%"}
            light={"/movie_preview.jpg"}
            className="movie-player"
            url="https://sayoga-content.s3.eu-west-2.amazonaws.com/sayoga-promo-pl-1080p.mp4"
            offset={true}
            playing={true}
          />
        </div>
      </MoviePlayerStyles>
      <div className="bg-orange-dark w-full">
        <Container className="max-w-6xl flex flex-col sm:flex-row justify-between p-12">
          <div className="pb-4 sm:pb-0">
            <SayogaLogo className="w-40 fill-svg-white" />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <a
              href="https://www.facebook.com/sayogapl"
              className="no-underline focus:no-underline focus:opacity-80 text-white"
            >
              <>
                <FacebookIcon className="fill-svg-white h-6 float-left mr-4" />{" "}
                <span>Facebook</span>
              </>
            </a>

            <a
              href="https://instagram.com/say_yoga"
              className="no-underline focus:no-underline focus:opacity-80 text-white"
            >
              <>
                <InstagramIcon className="fill-svg-white h-6 float-left mr-4" />{" "}
                <span>Instagram</span>
              </>
            </a>
          </div>
        </Container>
      </div>
    </>
  )
}

export default IndexPage
